<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.0002 2.24988C10.0719 2.24988 8.18682 2.82171 6.58344 3.89305C4.98006 4.96439 3.73038 6.48713 2.99242 8.26871C2.25447 10.0503 2.06139 12.0107 2.43759 13.902C2.8138 15.7933 3.7424 17.5306 5.10596 18.8942C6.46952 20.2577 8.2068 21.1863 10.0981 21.5625C11.9894 21.9387 13.9498 21.7457 15.7314 21.0077C17.513 20.2697 19.0357 19.0201 20.1071 17.4167C21.1784 15.8133 21.7502 13.9282 21.7502 11.9999C21.7475 9.41486 20.7194 6.93649 18.8915 5.1086C17.0636 3.28071 14.5853 2.25261 12.0002 2.24988ZM17.2502 12.7499H12.0002C11.8013 12.7499 11.6106 12.6709 11.4699 12.5302C11.3293 12.3896 11.2502 12.1988 11.2502 11.9999V6.74988C11.2502 6.55097 11.3293 6.3602 11.4699 6.21955C11.6106 6.0789 11.8013 5.99988 12.0002 5.99988C12.1992 5.99988 12.3899 6.0789 12.5306 6.21955C12.6712 6.3602 12.7502 6.55097 12.7502 6.74988V11.2499H17.2502C17.4492 11.2499 17.6399 11.3289 17.7806 11.4695C17.9212 11.6102 18.0002 11.801 18.0002 11.9999C18.0002 12.1988 17.9212 12.3896 17.7806 12.5302C17.6399 12.6709 17.4492 12.7499 17.2502 12.7499Z"
      fill="#333333"
    />
  </svg>
</template>

<script lang="ts" setup></script>
